/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"

const Spinner = (props) => {
    const { color } = props

    const componentStyle = css`
        width: 25px;
        height: 25px;
        border: 3px solid ${color};
        border-bottom: 3px solid rgba(0, 0, 0, 0);
        border-radius: 50%;
        animation: spin 1s linear infinite;
        float: left;

        .spinner--hidden {
            display: none;
        }

        @keyframes spin {
            to {
                transform: rotate(360deg);
            }
        }
    `

    return <div css={componentStyle}></div>
}

export default Spinner
