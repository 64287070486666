/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import { Container } from "react-bootstrap"
import Store from "./containers/Store"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Header from "./components/Header"
import Questionnaire from "./components/Questionnaire"
import SendQuestionnaire from "./components/SendQuestionnaire"

const componentStyle = css`
    background: #fff;
    border-radius: 10px;
    padding: 40px;
`

function App() {
  
    return (
        <Container fluid css={componentStyle}>
            <Store>
                <Header />
                <BrowserRouter>
                    <Routes>
                        <Route 
                            path="/seller-questionnaire/:questionnaireId"
                            element={<Questionnaire/>}>                           
                        </Route>
                        <Route 
                            path="/form-x2/send-form/:listingId"
                            element={<SendQuestionnaire/>}>                           
                        </Route>
                        <Route 
                            path="/form-x2/resend-form/:formId"
                            element={<SendQuestionnaire/>}>                           
                        </Route>
                        <Route 
                            path="/"
                            element={<Questionnaire/>}>                           
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Store>
        </Container>
    )
}

export default App
