/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import { useState, useEffect } from "react"
import { Row, Col, Form, Button } from "react-bootstrap"
import { QuestionnaireContainer } from "../containers/QuestionnaireContainer"

const componentStyle = css``

function Page4() {
    const questionnaireContainer = QuestionnaireContainer.useContainer()

    const [isValidating, setIsValidating] = useState(false)
    const [error, setError] = useState("")

    const next = async (advance, back) => {
        setIsValidating(true)

        if (validate(true)) {
            await questionnaireContainer.saveQuestionnaire()
            if(advance){
                questionnaireContainer.advancePage(back)
            }
            setIsValidating(false)
        }
    }

    const previous = () => {
        questionnaireContainer.advancePage(true)
    }

    useEffect(() => {
        validate()
    }, [questionnaireContainer.answers])

    useEffect(() => {
        if (isValidating) {
            validate(false)
        }
    }, [isValidating])

    const validate = (forceValidate) => {
        if (!isValidating && !forceValidate) {
            return false
        }

        const requiredFields = [
            "Products_And_Services_Offered",
            "Estimated_Revenue_Percentages",
            "Sell_Distribute_Products",
            "Ideal_Client",
            "Methods_And_Areas_Of_Marketing",
            "Biggest_Growth_Opportunities",
            "Direct_Competitors",
            "Wholesale_Value",
            "Value_Of_Furniture_Fixtures_Equipment"
        ]

        let error = false

        requiredFields.map((field) => {
            if (!questionnaireContainer.answers["c_" + field]) {
                error = true
            }
        })

        if (error) {
            setError("Please complete required fields")
            return false
        } else {
            setError("")
            setIsValidating("")
            return true
        }
    }

    return (
        <div css={componentStyle}>
            <Row>
                <Col>
                    <h2>Products / Services, Competition &amp; Growth</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Products_And_Services_Offered
                                ? "error"
                                : ""
                        }>
                        Describe the products and services you offer<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Products_And_Services_Offered"
                        value={questionnaireContainer.answers.c_Products_And_Services_Offered}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Estimated_Revenue_Percentages
                                ? "error"
                                : ""
                        }>
                        Estimate the percentage of revenue that each product/service accounts for
                        <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Estimated_Revenue_Percentages"
                        value={questionnaireContainer.answers.c_Estimated_Revenue_Percentages}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Sell_Distribute_Products ? "error" : ""
                        }>
                        How does your company sell/distribute your products and services?
                        <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Sell_Distribute_Products"
                        value={questionnaireContainer.answers.c_Sell_Distribute_Products}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Ideal_Client ? "error" : ""}>
                        Who is your ideal client?<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Ideal_Client"
                        value={questionnaireContainer.answers.c_Ideal_Client}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Methods_And_Areas_Of_Marketing
                                ? "error"
                                : ""
                        }>
                        What are your current methods and areas of marketing?<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Methods_And_Areas_Of_Marketing"
                        value={questionnaireContainer.answers.c_Methods_And_Areas_Of_Marketing}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Biggest_Growth_Opportunities
                                ? "error"
                                : ""
                        }>
                        What do you see as the biggest growth opportunities for your business?
                        <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Biggest_Growth_Opportunities"
                        value={questionnaireContainer.answers.c_Biggest_Growth_Opportunities}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Direct_Competitors ? "error" : ""}>
                        Who are your direct competitors?<span className="required">*</span>
                    </Form.Label>
                    <Form.Text muted>Local and National</Form.Text>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Direct_Competitors"
                        value={questionnaireContainer.answers.c_Direct_Competitors}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label>What sets you apart from your competitors?</Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Sets_Apart_From_Competitors"
                        value={questionnaireContainer.answers.c_Sets_Apart_From_Competitors}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label>What is the current situation in your industry?</Form.Label>
                    <Form.Text muted>
                        Increasing/decreasing demand, entrants of new players, government regulations, etc...
                    </Form.Text>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Current_Situation_In_Industry"
                        value={questionnaireContainer.answers.c_Current_Situation_In_Industry}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Wholesale_Value ? "error" : ""}>
                        Approximately how much do you have in saleable inventory (wholesale value)?
                        <span className="required">*</span>
                    </Form.Label>
                    <Form.Text muted>Enter numeric values only (i.e., 35,000)</Form.Text>
                    <Form.Control
                        type="text"
                        size="sm"
                        name="Wholesale_Value"
                        value={questionnaireContainer.answers.c_Wholesale_Value}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Value_Of_Furniture_Fixtures_Equipment
                                ? "error"
                                : ""
                        }>
                        Approximately what is the current market value of your furniture, fixtures and equipment?
                        <span className="required">*</span>
                    </Form.Label>
                    <Form.Text muted>Enter numeric values only (i.e., 35,000)</Form.Text>
                    <Form.Control
                        type="text"
                        size="sm"
                        name="Value_Of_Furniture_Fixtures_Equipment"
                        value={questionnaireContainer.answers.c_Value_Of_Furniture_Fixtures_Equipment}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    {error && <p className="error">{error}</p>}
                    <div className="buttons">
                        <Button
                            className="next"
                            size="sm"
                            variant="success"
                            onClick={()=>next(true)}>
                            Save &amp; Continue
                        </Button>
                        <Button
                            className="next"
                            size="sm"
                            variant="success"
                            onClick={()=>next(false)}>
                            Save for Later
                        </Button>
                        <Button
                            className="next"
                            size="sm"
                            variant="primary"
                            onClick={()=>next(true, true)}>
                            Save &amp; Back
                        </Button>                          
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Page4
