/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import { Row, Col } from "react-bootstrap"

const componentStyle = css`
    img{
        margin-bottom: 20px;
    }

    h1{
        font-size: 26px;
    }
`

function Header() {
  
    return (

        <div css={componentStyle}>
            <Row>
                <Col>
                    <img 
                        src="/img/logo.png"
                        alt="Transworld Business Advisors" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Transworld Business Advisors: Seller Questionnaire</h1>
                </Col>
            </Row>
        </div>
       
    )
}

export default Header
