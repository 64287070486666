/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import { useState, useEffect } from "react"
import { Row, Col, Form, Button } from "react-bootstrap"
import { QuestionnaireContainer } from "../containers/QuestionnaireContainer"

const componentStyle = css``

function Page5() {
    const questionnaireContainer = QuestionnaireContainer.useContainer()

    const [isValidating, setIsValidating] = useState(false)
    const [error, setError] = useState("")

    const next = async (advance, back) => {
        setIsValidating(true)

        if (validate(true)) {
            await questionnaireContainer.saveQuestionnaire()
            if(advance){
                questionnaireContainer.advancePage(back)
            }
            setIsValidating(false)
        }
    }

    useEffect(() => {
        validate()
    }, [questionnaireContainer.answers])

    useEffect(() => {
        if (isValidating) {
            validate(false)
        }
    }, [isValidating])

    const validate = (forceValidate) => {
        if (!isValidating && !forceValidate) {
            return false
        }

        const requiredFields = [
            "Company_Founder",
            "Products_And_Services_Offered",
            "Company_Moved",
            "Interest_In_Staying",
            "Stay_On_To_Train",
            "Stay_On_To_Consult",
            "Non_Compete_Timeframe",
            "Seller_Financing",
            "Current_On_Rent_Mortgage"
        ]

        let error = false

        requiredFields.map((field) => {
            if (!questionnaireContainer.answers["c_" + field]) {
                error = true
            }
        })

        if (
            !questionnaireContainer.answers.c_Legal_Situations_Lawsuits &&
            !questionnaireContainer.answers.c_Legal_Situations_Lien &&
            !questionnaireContainer.answers.c_Legal_Situations_Behind_On_Rent &&
            !questionnaireContainer.answers.c_Legal_Situations_Brokerage_Firm &&
            !questionnaireContainer.answers.c_Legal_Situations_Debts_At_Closing &&
            !questionnaireContainer.answers.c_Legal_Situations_None
        ) {
            error = true
        }

        if (error) {
            setError("Please complete required fields")
            return false
        } else {
            setError("")
            setIsValidating("")
            return true
        }
    }

    const previous = () => {
        questionnaireContainer.advancePage(true)
    }

    return (
        <div css={componentStyle}>
            <Row>
                <Col>
                    <h2>**Information for Transworld Business Advisors Only**</h2>
                    <p>
                        From this point in the questionnaire forward, the information will only be used internally to
                        understand your business fully.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Company_Founder ? "error" : ""}>
                        Who is the company founder?<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        size="sm"
                        name="Company_Founder"
                        value={questionnaireContainer.answers.c_Company_Founder}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Company_Name_Change ? "error" : ""
                        }>
                        Has the company name been changed?<span className="required">*</span>
                    </Form.Label>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        value="Yes"
                        name="Company_Name_Change"
                        checked={questionnaireContainer.answers.c_Company_Name_Change === "Yes"}
                        onChange={questionnaireContainer.setAnswer}
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        value="No"
                        name="Company_Name_Change"
                        checked={questionnaireContainer.answers.c_Company_Name_Change === "No"}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={
                            isValidating &&
                            !questionnaireContainer.answers.c_Legal_Situations_Lawsuits &&
                            !questionnaireContainer.answers.c_Legal_Situations_Lien &&
                            !questionnaireContainer.answers.c_Legal_Situations_Behind_On_Rent &&
                            !questionnaireContainer.answers.c_Legal_Situations_Brokerage_Firm &&
                            !questionnaireContainer.answers.c_Legal_Situations_Debts_At_Closing &&
                            !questionnaireContainer.answers.c_Legal_Situations_None
                                ? "error"
                                : ""
                        }>
                        Do any of these legal situations apply to you or your business?
                        <span className="required">*</span>
                    </Form.Label>
                    <Form.Check
                        type="checkbox"
                        label="Lawsuits pending against my business"
                        checked={questionnaireContainer.answers.c_Legal_Situations_Lawsuits}
                        onChange={questionnaireContainer.setAnswer}
                        name="Legal_Situations_Lawsuits"
                    />
                    <Form.Check
                        type="checkbox"
                        label="There is a lien on my business or property (tax, federal, etc...)"
                        checked={questionnaireContainer.answers.c_Legal_Situations_Lien}
                        onChange={questionnaireContainer.setAnswer}
                        name="Legal_Situations_Lien"
                    />
                    <Form.Check
                        type="checkbox"
                        label="I am behind on my rent or mortgage payments related to my business property"
                        checked={questionnaireContainer.answers.c_Legal_Situations_Behind_On_Rent}
                        onChange={questionnaireContainer.setAnswer}
                        name="Legal_Situations_Behind_On_Rent"
                    />
                    <Form.Check
                        type="checkbox"
                        label="I have a current or past agreement with a brokerage firm"
                        checked={questionnaireContainer.answers.c_Legal_Situations_Brokerage_Firm}
                        onChange={questionnaireContainer.setAnswer}
                        name="Legal_Situations_Brokerage_Firm"
                    />
                    <Form.Check
                        type="checkbox"
                        label="I have debts that need to be paid at closing"
                        checked={questionnaireContainer.answers.c_Legal_Situations_Debts_At_Closing}
                        onChange={questionnaireContainer.setAnswer}
                        name="Legal_Situations_Debts_At_Closing"
                    />
                    <Form.Check
                        type="checkbox"
                        label="None"
                        checked={questionnaireContainer.answers.c_Legal_Situations_None}
                        onChange={questionnaireContainer.setAnswer}
                        name="Legal_Situations_None"
                    />

                    <Form.Label>
                        If you check anything other than &quot;None&quot; please include the details below
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Legal_Details"
                        value={questionnaireContainer.answers.c_Legal_Details || ""}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Company_Moved ? "error" : ""}>
                        Has your company moved or expanded since opening?<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Company_Moved"
                        value={questionnaireContainer.answers.c_Company_Moved || ""}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Interest_In_Staying ? "error" : ""
                        }>
                        Do you have any interest in staying after the sale?<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        size="sm"
                        name="Interest_In_Staying"
                        value={questionnaireContainer.answers.c_Interest_In_Staying}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Stay_On_To_Train ? "error" : ""}>
                        Will you stay to train for a set period at no additional cost?
                        <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        size="sm"
                        name="Stay_On_To_Train"
                        value={questionnaireContainer.answers.c_Stay_On_To_Train}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Stay_On_To_Consult ? "error" : ""}>
                        Will you stay to consult for an extended period of time at an additional, agreed upon, rate?
                        <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        size="sm"
                        name="Stay_On_To_Consult"
                        value={questionnaireContainer.answers.c_Stay_On_To_Consult}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Non_Compete_Timeframe ? "error" : ""
                        }>
                        For how long are you willing to sign a non compete?<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        size="sm"
                        name="Non_Compete_Timeframe"
                        value={questionnaireContainer.answers.c_Non_Compete_Timeframe}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Seller_Financing ? "error" : ""}>
                        Are you open to seller financing?<span className="required">*</span>
                    </Form.Label>
                    <Form.Text muted>This will significantly improve the marketability of your business</Form.Text>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Seller_Financing"
                        value={questionnaireContainer.answers.c_Seller_Financing || ""}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Current_On_Rent_Mortgage ? "error" : ""
                        }>
                        Are you current on your rent / mortgage payments?<span className="required">*</span>
                    </Form.Label>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        value="Yes"
                        checked={questionnaireContainer.answers.c_Current_On_Rent_Mortgage === "Yes"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Current_On_Rent_Mortgage"
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        value="No"
                        checked={questionnaireContainer.answers.c_Current_On_Rent_Mortgage === "No"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Current_On_Rent_Mortgage"
                    />

                    <Form.Label>If you answered no please explain in detail here</Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Current_On_Rent_Mortgage_Details"
                        value={questionnaireContainer.answers.c_Current_On_Rent_Mortgage_Details || ""}
                        onChange={questionnaireContainer.setAnswer}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    {error && <p className="error">{error}</p>}
                    <div className="buttons">
                        <Button
                            className="next"
                            size="sm"
                            variant="success"
                            onClick={()=>next(true)}>
                            Save &amp; Continue
                        </Button>
                        <Button
                            className="next"
                            size="sm"
                            variant="success"
                            onClick={()=>next(false)}>
                            Save for Later
                        </Button>
                        <Button
                            className="next"
                            size="sm"
                            variant="primary"
                            onClick={()=>next(true, true)}>
                            Save &amp; Back
                        </Button>                       
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Page5
