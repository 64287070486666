/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import { useState, useEffect } from "react"
import { Row, Col, Form, Button } from "react-bootstrap"
import { QuestionnaireContainer } from "../containers/QuestionnaireContainer"

const componentStyle = css``

function Page6() {
    const questionnaireContainer = QuestionnaireContainer.useContainer()

    const [isValidating, setIsValidating] = useState(false)
    const [error, setError] = useState("")

    const next = async (advance, back) => {
        setIsValidating(true)

        if (validate(true)) {
            await questionnaireContainer.saveQuestionnaire()
            if(advance){
                questionnaireContainer.advancePage(back)
            }
            setIsValidating(false)
        }
    }

    const previous = () => {
        questionnaireContainer.advancePage(true)
    }

    useEffect(() => {
        validate()
    }, [questionnaireContainer.answers])

    useEffect(() => {
        if (isValidating) {
            validate(false)
        }
    }, [isValidating])

    const validate = (forceValidate) => {
        if (!isValidating && !forceValidate) {
            return false
        }

        const requiredFields = [
            "Reason_For_Sale",
            "Others_Know_About_Sale",
            "Others_Involved_In_Sale",
            "Partners_Or_Investors",
            "Companies_Apt_To_Purchase",
            "Timeframe_To_Sell",
            "Financial_Documents",
            "Information_Correct"
        ]

        let error = false

        requiredFields.map((field) => {
            if (!questionnaireContainer.answers["c_" + field]) {
                error = true
            }
        })

        if (error) {
            setError("Please complete required fields")
            return false
        } else {
            setError("")
            setIsValidating("")
            return true
        }
    }

    return (
        <div css={componentStyle}>
            <Row>
                <Col>
                    <h2>Information for Transworld - Part 2</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Reason_For_Sale ? "error" : ""}>
                        Reason for sale
                    </Form.Label>
                    <Form.Text muted>
                        This will significantly improve the marketability of your business
                        <span className="required">*</span>
                    </Form.Text>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Reason_For_Sale"
                        value={questionnaireContainer.answers.c_Reason_For_Sale}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Others_Know_About_Sale ? "error" : ""
                        }>
                        Does anyone else know about the sale?<span className="required">*</span>
                    </Form.Label>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        value="Yes"
                        checked={questionnaireContainer.answers.c_Others_Know_About_Sale === "Yes"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Others_Know_About_Sale"
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        value="No"
                        checked={questionnaireContainer.answers.c_Others_Know_About_Sale === "No"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Others_Know_About_Sale"
                    />

                    <Form.Label>If yes, please list names and titles.</Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Others_Know_About_Sale_Details"
                        value={questionnaireContainer.answers.c_Others_Know_About_Sale_Details}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Others_Involved_In_Sale ? "error" : ""
                        }>
                        Is anyone else involved in the decision of the sale?<span className="required">*</span>
                    </Form.Label>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        value="Yes"
                        checked={questionnaireContainer.answers.c_Others_Involved_In_Sale === "Yes"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Others_Involved_In_Sale"
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        value="No"
                        checked={questionnaireContainer.answers.c_Others_Involved_In_Sale === "No"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Others_Involved_In_Sale"
                    />

                    <Form.Label>If yes, please include names and contact information.</Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Others_Involved_In_Sale_Details"
                        value={questionnaireContainer.answers.c_Others_Involved_In_Sale_Details}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Partners_Or_Investors ? "error" : ""
                        }>
                        Do you have any partners or investors?<span className="required">*</span>
                    </Form.Label>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        value="Yes"
                        checked={questionnaireContainer.answers.c_Partners_Or_Investors === "Yes"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Partners_Or_Investors"
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        value="No"
                        checked={questionnaireContainer.answers.c_Partners_Or_Investors === "No"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Partners_Or_Investors"
                    />

                    <Form.Label>
                        If you have partners or investors please list them and their equity percentages.
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Partners_Or_Investors_Details"
                        value={questionnaireContainer.answers.c_Partners_Or_Investors_Details}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label>
                        Is there any information above (aside from company, owner, business name, etc.) that would give
                        away your identity to a competitor who may see the ad?
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Other_Identity_Information"
                        value={questionnaireContainer.answers.c_Other_Identity_Information}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label>Are there any specific companies that you don\'t want us contacting?</Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="No_Contact_Companies"
                        value={questionnaireContainer.answers.c_No_Contact_Companies}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label>Is there anything you would like to add that you feel we should know?</Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Other_Information"
                        value={questionnaireContainer.answers.c_Other_Information}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Companies_Apt_To_Purchase ? "error" : ""
                        }>
                        Are there specific companies or industries you feel would be most apt to purchase your business?
                        <span className="required">*</span>
                    </Form.Label>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        value="Yes"
                        checked={questionnaireContainer.answers.c_Companies_Apt_To_Purchase === "Yes"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Companies_Apt_To_Purchase"
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        value="No"
                        checked={questionnaireContainer.answers.c_Companies_Apt_To_Purchase === "No"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Companies_Apt_To_Purchase"
                    />

                    <Form.Label>If yes, please list.</Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Companies_Apt_To_Purchase_Details"
                        value={questionnaireContainer.answers.c_Companies_Apt_To_Purchase_Details}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Timeframe_To_Sell ? "error" : ""}>
                        Is there a timeframe or deadline by which you must sell the business?
                        <span className="required">*</span>
                    </Form.Label>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        value="Yes"
                        checked={questionnaireContainer.answers.c_Timeframe_To_Sell === "Yes"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Timeframe_To_Sell"
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        value="No"
                        checked={questionnaireContainer.answers.c_Timeframe_To_Sell === "No"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Timeframe_To_Sell"
                    />

                    <Form.Label>If yes, please include and explain.</Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Timeframe_To_Sell_Details"
                        value={questionnaireContainer.answers.c_Timeframe_To_Sell_Details}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Financial_Documents ? "error" : ""
                        }>
                        Do you have professionally prepared financial documents including Profit &amp; Loss statements,
                        Balance Sheets, and Tax Returns for the past 3 years?<span className="required">*</span>
                    </Form.Label>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        value="Yes"
                        checked={questionnaireContainer.answers.c_Financial_Documents === "Yes"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Financial_Documents"
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        value="No"
                        checked={questionnaireContainer.answers.c_Financial_Documents === "No"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Financial_Documents"
                    />

                    <Form.Label>If no, please explain.</Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Financial_Documents_Details"
                        value={questionnaireContainer.answers.c_Financial_Documents_Details}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Information_Correct ? "error" : ""
                        }>
                        All information included on this form is accurate and included, to my knowledge.
                        <span className="required">*</span>
                    </Form.Label>

                    <Form.Check
                        type="checkbox"
                        label="Yes"
                        checked={questionnaireContainer.answers.c_Information_Correct}
                        onChange={questionnaireContainer.setAnswer}
                        name="Information_Correct"
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    {error && <p className="error">{error}</p>}
                    <div className="buttons">
                        <Button
                            className="next"
                            size="sm"
                            variant="success"
                            onClick={next}>
                            Submit
                        </Button>
                        <Button
                            className="next"
                            size="sm"
                            variant="success"
                            onClick={()=>next(false)}>
                            Save for Later
                        </Button>
                        <Button
                            className="next"
                            size="sm"
                            variant="primary"
                            onClick={()=>next(true, true)}>
                            Save &amp; Back
                        </Button>   
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Page6
