/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import { useState, useEffect } from "react"
import { Row, Col, Form, Button} from "react-bootstrap"
import { QuestionnaireContainer } from "../containers/QuestionnaireContainer"

const componentStyle = css`
`

function Page2() {
    const questionnaireContainer = QuestionnaireContainer.useContainer()

    const [ isValidating, setIsValidating ] = useState(false)
    const [ error, setError ] = useState("")

    const next = async (advance, back) => {
        setIsValidating(true)

        if (validate(true)) {
            await questionnaireContainer.saveQuestionnaire()
            if(advance){
                questionnaireContainer.advancePage(back)
            }
            setIsValidating(false)
        }
    }

    const previous = ()=>{
        questionnaireContainer.advancePage(true)
    }

    useEffect(()=>{
        validate()
    }, [questionnaireContainer.answers])

    useEffect(()=>{
        if(isValidating){
            validate(false)
        }
    }, [isValidating])

    const validate = (forceValidate) =>{

        if(!isValidating && !forceValidate){
            return false
        }        

        const requiredFields = [
            "Years_At_Current_Location",
            "Suitable_Company_Use",
            "Facility_Type",
            "Building_Type",
            "Square_Feet",
            "Special_Zoning_Requirements",
            "Type_Of_Lease",
            "Monthly_Lease_Payment",
            "Lease_Expiration_Options",
            "Option_To_Purchase"
        ]

        let error = false

        requiredFields.map(field =>{
            if(!questionnaireContainer.answers["c_" + field]){
                error = true
            }
        })
        
        if(error){
            setError("Please complete required fields")
            return false
        }else{
            setError("")
            setIsValidating("")
            return true
        }
    }
  
    return (
        <div css={componentStyle}>
            <Row>
                <Col>
                    <h2>Location Overview</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Years_At_Current_Location ? "error" : ""}>
                        Years at current location<span className="required">*</span></Form.Label>
                    <Form.Text muted>Round to nearest year</Form.Text>
                    <Form.Control 
                        type="number"
                        size="sm"
                        name="Years_At_Current_Location"
                        value={questionnaireContainer.answers.c_Years_At_Current_Location}
                        onChange={questionnaireContainer.setAnswer}
                        required />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Suitable_Company_Use ? "error" : ""}>
                        How suitable is the current location for company use?<span className="required">*</span></Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Suitable_Company_Use"
                        value={questionnaireContainer.answers.c_Suitable_Company_Use}
                        onChange={questionnaireContainer.setAnswer}
                        required />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Facility_Type ? "error" : ""}>
                        Facility Type<span className="required">*</span></Form.Label>
                    <Form.Check
                        type="radio"
                        label="Rent"
                        name="Facility_Type"
                        value="Rent"
                        checked={questionnaireContainer.answers.c_Facility_Type === "Rent"}
                        onChange={questionnaireContainer.setAnswer} />
                    <Form.Check
                        type="radio"
                        label="Own"
                        name="Facility_Type"
                        value="Own"
                        checked={questionnaireContainer.answers.c_Facility_Type === "Own"}
                        onChange={questionnaireContainer.setAnswer}/>

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Building_Type ? "error" : ""}>
                        Building Type<span className="required">*</span></Form.Label>
                    <Form.Check
                        type="radio"
                        label="Home Office"
                        name="Building_Type"
                        value="Own"
                        checked={questionnaireContainer.answers.c_Building_Type === "Own"}
                        onChange={questionnaireContainer.setAnswer}/>
                    <Form.Check
                        type="radio"
                        label="Strip Mall"
                        name="Building_Type"
                        value="Strip Mall"
                        checked={questionnaireContainer.answers.c_Building_Type === "Strip Mall"}
                        onChange={questionnaireContainer.setAnswer}/>
                    <Form.Check
                        type="radio"
                        label="Standalone Building"
                        name="Building_Type"
                        value="Standalone Building"
                        checked={questionnaireContainer.answers.c_Building_Type === "Standalone Building"}
                        onChange={questionnaireContainer.setAnswer}/>
                    <Form.Check
                        type="radio"
                        label="Office Building"
                        name="Building_Type"
                        value="Office Building"
                        checked={questionnaireContainer.answers.c_Building_Type === "Office Building"}
                        onChange={questionnaireContainer.setAnswer}/>
                    <Form.Check
                        type="radio"
                        label="Industrial"
                        name="Building_Type"
                        value="Industrial"
                        checked={questionnaireContainer.answers.c_Building_Type === "Industrial"}
                        onChange={questionnaireContainer.setAnswer}/>
                
                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Square_Feet ? "error" : ""}>
                        Size<span className="required">*</span></Form.Label>
                    <Form.Text muted>(i.e., 2200 square feet, 875 square meters)</Form.Text>
                    <Form.Control 
                        type="text"
                        size="sm"
                        name="Square_Feet"
                        value={questionnaireContainer.answers.c_Square_Feet}
                        onChange={questionnaireContainer.setAnswer}
                        required/>

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Special_Zoning_Requirements ? "error" : ""}>
                        Are there any special zoning requirements for your location?<span className="required">*</span></Form.Label>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        value="Yes"
                        checked={questionnaireContainer.answers.c_Special_Zoning_Requirements === "Yes"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Special_Zoning_Requirements"/>
                    <Form.Check
                        type="radio"
                        label="No"
                        value="No"
                        checked={questionnaireContainer.answers.c_Special_Zoning_Requirements === "No"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Special_Zoning_Requirements"/>
                
                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Type_Of_Lease ? "error" : ""}>
                        Type of Lease<span className="required">*</span></Form.Label>
                    <Form.Text muted>If applicable</Form.Text>
                    <Form.Check
                        type="radio"
                        label="Gross"
                        value="Gross"
                        checked={questionnaireContainer.answers.c_Type_Of_Lease === "Gross"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Type_Of_Lease"/>
                    <Form.Check
                        type="radio"
                        label="Triple Net"
                        value="Triple Net"
                        checked={questionnaireContainer.answers.c_Type_Of_Lease === "Triple Net"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Type_Of_Lease"/>
                    <Form.Check
                        type="radio"
                        label="Full Service"
                        value="Full Service"
                        checked={questionnaireContainer.answers.c_Type_Of_Lease === "Full Service"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Type_Of_Lease"/>
                    <Form.Check
                        type="radio"
                        label="Building Owned"
                        value="Building Owned"
                        checked={questionnaireContainer.answers.c_Type_Of_Lease === "Building Owned"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Type_Of_Lease"/>
                    <Form.Check
                        type="radio"
                        label="Other"
                        value="Other"
                        checked={questionnaireContainer.answers.c_Type_Of_Lease === "Other"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Type_Of_Lease"/>
        
                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Monthly_Lease_Payment ? "error" : ""}>
                        Monthly Lease Payment<span className="required">*</span></Form.Label>
                    <Form.Text muted>Including CAM and Expenses. Enter numeric values only (i.e., 35,000)</Form.Text>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Monthly_Lease_Payment"
                        value={questionnaireContainer.answers.c_Monthly_Lease_Payment}
                        onChange={questionnaireContainer.setAnswer}
                        required />
                
                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Lease_Expiration_Options ? "error" : ""}>
                        Lease Expiration and options<span className="required">*</span></Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Lease_Expiration_Options"
                        value={questionnaireContainer.answers.c_Lease_Expiration_Options}
                        onChange={questionnaireContainer.setAnswer}
                        required/>

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Option_To_Purchase ? "error" : ""}>
                        Is there an option to purchase?<span className="required">*</span></Form.Label>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        value="Yes"
                        checked={questionnaireContainer.answers.c_Option_To_Purchase === "Yes"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Option_To_Purchase"/>
                    <Form.Check
                        type="radio"
                        label="No"
                        value="No"
                        checked={questionnaireContainer.answers.c_Option_To_Purchase === "No"}
                        onChange={questionnaireContainer.setAnswer}
                        name="Option_To_Purchase"/>
                </Col>
            </Row>

            <Row>
                <Col>
                    {error && <p className="error">{error}</p>}
                    <div className="buttons">
                        <Button
                            className="next"
                            size="sm"
                            variant="success"
                            onClick={()=>next(true)}>
                            Save &amp; Continue
                        </Button>
                        <Button
                            className="next"
                            size="sm"
                            variant="success"
                            onClick={()=>next(false)}>
                            Save for Later
                        </Button>
                        <Button
                            className="next"
                            size="sm"
                            variant="primary"
                            onClick={()=>next(true, true)}>
                            Save &amp; Back
                        </Button>                         
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Page2