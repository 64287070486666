/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import { Row, Col } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Spinner from "./Spinner"
import { QuestionnaireContainer } from "../containers/QuestionnaireContainer"
import * as api from "../api"

const componentStyle = css`
    h4 {
        color: #093f1d;
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 22px;
    }

    a {
        color: #927245;
    }

    .loading-background {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(355, 255, 255, 0.7);
        top: 0;
        left: 0;
        z-index: 10;
    }

    .spinner {
        margin-left: calc(50% - 12px);
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 11;
    }
`

function SendQuestionnaire() {
    
    const { formId } = useParams()
    const { listingId } = useParams()

    const [isLoading, setIsLoading] = useState(true)
    const [url, setUrl] = useState("")

    useEffect(() => {
        getQuestionnaire()
    }, [])

    const getQuestionnaire = async () => {

        let promise

        if(formId){
            promise = await api.getPhpId(formId)
        }else if(listingId){
            promise = await api.createQuestionnaire(listingId)
        }
      
        setUrl("https://seller.tworld.com/seller-questionnaire/" + promise)
        setIsLoading(false)
    }

    return (
        <div css={componentStyle}>
            <Row>
                <Col>
                    <h4 className="title">Send Seller Questionnaire</h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        <strong>
                            Copy and paste the link below to your email client (Gmail, Outlook, etc.), and send to your
                            Seller.
                        </strong>
                    </p>
                    <p>
                        <strong>Copy this link:</strong> <a href={url}>{url}</a>
                    </p>
                    <p>
                        <strong>Suggested Subject:</strong> New Seller Questionnaire - Please complete
                    </p>
                    <p>
                        <strong>Suggested Message:</strong> Thank you for listing your business with Transworld Business
                        Advisors! We take this responsibility seriously and want to complete a successful and smooth
                        transaction! To that end, as discussed, we need your help. Please fill out this questionnaire
                        and submit at your earliest convenience. The more info the better. Don't hide information- give
                        it all to us! We are here to help... and the more info we can give prospective buyers, the
                        better.
                    </p>

                    <p>
                        Thanks for your help! We look forward to working with you on this exciting and important
                        transaction.
                    </p>
                </Col>
            </Row>

            {isLoading && 
                <div className="loading-background">
                    <div className="spinner">
                        <Spinner color="#07481f" />
                    </div>
                </div>
            }
        </div>

        
    )
}

export default SendQuestionnaire
