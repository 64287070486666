/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import { useState, useEffect } from "react"
import { Row, Col, Form, Button } from "react-bootstrap"
import { QuestionnaireContainer } from "../containers/QuestionnaireContainer"

const componentStyle = css``

function Page3() {
    const questionnaireContainer = QuestionnaireContainer.useContainer()

    const [isValidating, setIsValidating] = useState(false)
    const [error, setError] = useState("")

    const next = async (advance, back) => {
        setIsValidating(true)

        if (validate(true)) {
            await questionnaireContainer.saveQuestionnaire()
            if(advance){
                questionnaireContainer.advancePage(back)
            }
            setIsValidating(false)
        }
    }

    const previous = () => {
        questionnaireContainer.advancePage(true)
    }

    useEffect(() => {
        validate()
    }, [questionnaireContainer.answers])

    useEffect(() => {
        if (isValidating) {
            validate(false)
        }
    }, [isValidating])

    const validate = (forceValidate) => {
        if (!isValidating && !forceValidate) {
            return false
        }

        const requiredFields = [
            "Number_Of_Fulltime_Employees",
            "Number_Of_Parttime_Employees",
            "Number_Of_Contractors",
            "Longest_Tenured_Employee",
            "Manage_Business_Timeframe",
            "Train_New_Employee_Timeframe",
            "Owner_Performed_Functions"
        ]

        let error = false

        requiredFields.map((field) => {
            if (!questionnaireContainer.answers["c_" + field]) {
                error = true
            }
        })

        if (error) {
            setError("Please complete required fields")
            return false
        } else {
            setError("")
            setIsValidating("")
            return true
        }
    }

    return (
        <div css={componentStyle}>
            <Row>
                <Col>
                    <h2>Employees &amp; Management</h2>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Number_Of_Fulltime_Employees
                                ? "error"
                                : ""
                        }>
                        Number of full-time employees<span className="required">*</span>
                    </Form.Label>
                    <Form.Text muted>0 is acceptable</Form.Text>
                    <Form.Control
                        type="number"
                        size="sm"
                        name="Number_Of_Fulltime_Employees"
                        value={questionnaireContainer.answers.c_Number_Of_Fulltime_Employees}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Number_Of_Parttime_Employees
                                ? "error"
                                : ""
                        }>
                        Number of part-time employees<span className="required">*</span>
                    </Form.Label>
                    <Form.Text muted>0 is acceptable</Form.Text>
                    <Form.Control
                        type="number"
                        size="sm"
                        name="Number_Of_Parttime_Employees"
                        value={questionnaireContainer.answers.c_Number_Of_Parttime_Employees}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Number_Of_Contractors ? "error" : ""
                        }>
                        Number of contractors<span className="required">*</span>
                    </Form.Label>
                    <Form.Text muted>0 is acceptable</Form.Text>
                    <Form.Control
                        type="number"
                        size="sm"
                        name="Number_Of_Contractors"
                        value={questionnaireContainer.answers.c_Number_Of_Contractors}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Longest_Tenured_Employee ? "error" : ""
                        }>
                        Who is the longest tenured employee?<span className="required">*</span>
                    </Form.Label>
                    <Form.Text muted>Also briefly describe their role in this company</Form.Text>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Longest_Tenured_Employee"
                        value={questionnaireContainer.answers.c_Longest_Tenured_Employee}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Manage_Business_Timeframe ? "error" : ""
                        }>
                        How long would it take a buyer with reasonable skills but little direct knowledge to learn
                        enough to manage this business?<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Manage_Business_Timeframe"
                        value={questionnaireContainer.answers.c_Manage_Business_Timeframe}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label>
                        Please list all managers, their roles and responsibilities, and tenure with the company.
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="List_All_Managers"
                        value={questionnaireContainer.answers.c_List_All_Managers}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label>What are the qualities of your best employee/employees?</Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Qualities_Of_Best_Employees"
                        value={questionnaireContainer.answers.c_Qualities_Of_Best_Employees}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Train_New_Employee_Timeframe
                                ? "error"
                                : ""
                        }>
                        How long does it take to recruit/train a new employee?<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Train_New_Employee_Timeframe"
                        value={questionnaireContainer.answers.c_Train_New_Employee_Timeframe}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label>What does employee turnover look like in your business?</Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Employee_Turnover"
                        value={questionnaireContainer.answers.c_Employee_Turnover}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Owner_Performed_Functions ? "error" : ""
                        }>
                        What functions does the owner perform that would need to be replaced by a buyer or employee
                        after this transition?<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Owner_Performed_Functions"
                        value={questionnaireContainer.answers.c_Owner_Performed_Functions}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    {error && <p className="error">{error}</p>}
                    <div className="buttons">
                        <Button
                            className="next"
                            size="sm"
                            variant="success"
                            onClick={()=>next(true)}>
                            Save &amp; Continue
                        </Button>
                        <Button
                            className="next"
                            size="sm"
                            variant="success"
                            onClick={()=>next(false)}>
                            Save for Later
                        </Button>
                        <Button
                            className="next"
                            size="sm"
                            variant="primary"
                            onClick={()=>next(true, true)}>
                            Save &amp; Back
                        </Button>                        
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Page3
