import React from "react";
import { QuestionnaireContainer } from "./QuestionnaireContainer"

const Store = ({ children }) => {
    return (
        <QuestionnaireContainer.Provider>
            {children}
        </QuestionnaireContainer.Provider>     
    )
}

export default React.memo(Store)