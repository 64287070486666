/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import { Row, Col } from "react-bootstrap"

const componentStyle = css`
    h5{
        margin-top: 20px;
    }
`

function Page7() {
    return (
        <div css={componentStyle}>
            <Row>
                <Col>
                    <h5>Thank you. Your submission is complete.</h5>
                </Col>
            </Row>
        </div>
    )
}

export default Page7
