import { sendGet, sendPost } from "./generic"

export const createQuestionnaire = (listingId) =>{
    return sendPost(`Questionnaire/${listingId}/Create`)
}

export const getQuestionnaire = (id)=> {
    return sendGet("Questionnaire/" + id)
}

export const postQuestionnaire = (payload)=> {
    return sendPost("Questionnaire/", payload)
}

export const getPhpId = (id) => {
    return sendGet(`Questionnaire/${id}/phpId`)
}