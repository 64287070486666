import { createContainer } from "unstated-next"
import { useEffect, useState } from "react"
import * as api from "../api/"
import update from "immutability-helper"

function useQuestionnaireContainer() {
    const [isLoading, setIsLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [noQuestionnaire, setNoQuestionnaire] = useState(false)
    const [questionnaireId, setQuestionnaireId] = useState()

    const [answers, setAnswers] = useState({
        c_Form_Submitted: false,
        c_RecordId: "",

        //page 1
        c_Business_Name: "",
        c_Street_Address: "",
        c_City: "",
        c_State: "",
        c_Zip_Code: "",
        c_CurrencySymbol: "",
        c_Telephone_Number: "",
        c_Website_Address: "",
        c_State_Of_Incorporation: "",
        c_Business_Operation_Hours: "",
        c_Legal_Organization_Type: "",
        c_Years_Owned: "",
        c_Years_In_Business: "",
        c_Intangible_Intellectual_Assets_Copyright: false,
        c_Intangible_Intellectual_Assets_Trademark: false,
        c_Intangible_Intellectual_Assets_Patents: false,
        c_Intangible_Intellectual_Assets_Software: false,
        c_Intangible_Intellectual_Assets_None: false,
        c_Business_Relocatable: "",
        //page 2
        c_Years_At_Current_Location: "",
        c_Suitable_Company_Use: "",
        c_Facility_Type: "",
        c_Building_Type: "",
        c_Square_Feet: "",
        c_Special_Zoning_Requirements: "",
        c_Type_Of_Lease: "",
        c_Monthly_Lease_Payment: "",
        c_Lease_Expiration_Options: "",
        c_Option_To_Purchase: "",
        //page 3
        c_Number_Of_Fulltime_Employees: "",
        c_Number_Of_Parttime_Employees: "",
        c_Number_Of_Contractors: "",
        c_Longest_Tenured_Employee: "",
        c_Manage_Business_Timeframe: "",
        c_List_All_Managers: "",
        c_Qualities_Of_Best_Employees: "",
        c_Train_New_Employee_Timeframe: "",
        c_Employee_Turnover: "",
        c_Owner_Performed_Functions: "",
        //page 4
        c_Products_And_Services_Offered: "",
        c_Estimated_Revenue_Percentages: "",
        c_Sell_Distribute_Products: "",
        c_Ideal_Client: "",
        c_Methods_And_Areas_Of_Marketing: "",
        c_Biggest_Growth_Opportunities: "",
        c_Direct_Competitors: "",
        c_Sets_Apart_From_Competitors: "",
        c_Current_Situation_In_Industry: "",
        c_Wholesale_Value: "",
        c_Value_Of_Furniture_Fixtures_Equipment: "",
        //page 5
        c_Company_Founder: "",
        c_Company_Name_Change: "",
        c_Legal_Situations_Lawsuits: false,
        c_Legal_Situations_Lien: false,
        c_Legal_Situations_Behind_On_Rent: false,
        c_Legal_Situations_Brokerage_Firm: false,
        c_Legal_Situations_Debts_At_Closing: false,
        c_Legal_Situations_None: false,
        c_Legal_Details: "",
        c_Company_Moved: "",
        c_Interest_In_Staying: "",
        c_Stay_On_To_Train: "",
        c_Stay_On_To_Consult: "",
        c_Non_Compete_Timeframe: "",
        c_Seller_Financing: "",
        c_Current_On_Rent_Mortgage: "",
        c_Current_On_Rent_Mortgage_Details: "",
        //page 6
        c_Reason_For_Sale: "",
        c_Others_Know_About_Sale: "",
        c_Others_Know_About_Sale_Details: "",
        c_Others_Involved_In_Sale: "",
        c_Others_Involved_In_Sale_Details: "",
        c_Partners_Or_Investors: "",
        c_Partners_Or_Investors_Details: "",
        c_Other_Identity_Information: "",
        c_No_Contact_Companies: "",
        c_Other_Information: "",
        c_Companies_Apt_To_Purchase: "",
        c_Companies_Apt_To_Purchase_Details: "",
        c_Timeframe_To_Sell: "",
        c_Timeframe_To_Sell_Details: "",
        c_Financial_Documents: "",
        c_Financial_Documents_Details: "",
        c_Information_Correct: false
    })

    useEffect(() => {
        console.log("page", currentPage)
    }, [currentPage])

    const getQuestionnaire = async (id) => {
        setQuestionnaireId(id)

        const promise = await api.getQuestionnaire(id)
        console.log(promise)

        if (promise.length > 0) {

            console.log("submitted", promise[0].c_Form_Submitted)
            
            promise[0].c_RecordId = promise[0].id


            promise[0].c_Form_Submitted = promise[0].c_Form_Submitted === "1" ? true : false

            
            promise[0].c_Intangible_Intellectual_Assets_Copyright = promise[0].c_Intangible_Intellectual_Assets_Copyright === "1" ? true : false
            promise[0].c_Intangible_Intellectual_Assets_Trademark = promise[0].c_Intangible_Intellectual_Assets_Trademark === "1" ? true : false
            promise[0].c_Intangible_Intellectual_Assets_Patents = promise[0].c_Intangible_Intellectual_Assets_Patents === "1" ? true : false
            promise[0].c_Intangible_Intellectual_Assets_Software = promise[0].c_Intangible_Intellectual_Assets_Software === "1" ? true : false
            promise[0].c_Intangible_Intellectual_Assets_None = promise[0].c_Intangible_Intellectual_Assets_None === "1" ? true : false
            promise[0].c_Legal_Situations_Lawsuits = promise[0].c_Legal_Situations_Lawsuits === "1" ? true : false
            promise[0].c_Legal_Situations_Lien = promise[0].c_Legal_Situations_Lien === "1" ? true : false
            promise[0].c_Legal_Situations_Behind_On_Rent = promise[0].c_Legal_Situations_Behind_On_Rent === "1" ? true : false
            promise[0].c_Legal_Situations_Brokerage_Firm = promise[0].c_Legal_Situations_Brokerage_Firm === "1" ? true : false
            promise[0].c_Legal_Situations_Debts_At_Closing = promise[0].c_Legal_Situations_Debts_At_Closing === "1" ? true : false
            promise[0].c_Legal_Situations_None = promise[0].c_Legal_Situations_None === "1" ? true : false
            promise[0].c_Information_Correct = promise[0].c_Information_Correct === "1" ? true : false

            setAnswers(promise[0])
        } else {
            setNoQuestionnaire(true)
        }

        setIsLoading(false)
    }

    const setAnswer = (e) => {
        const name = "c_" + e.currentTarget.name
        let value = e.currentTarget.value
        const type = e.currentTarget.type
        const checked = e.currentTarget.checked

        if (type === "checkbox") {
            value = checked
        }

        const oldAnswers = answers
        const newAnswers = update(oldAnswers, {
            $merge: {
                [name]: value
            }
        })

        console.log(name, value)

        setAnswers(newAnswers)
    }

    const saveQuestionnaire = async () => {
        console.log("save", currentPage)
        const oldData = answers 
        setIsLoading(true)

        let newData = update(oldData, {$merge: {
            c_Intangible_Intellectual_Assets_Copyright: answers.c_Intangible_Intellectual_Assets_Copyright === true ? "1" : "0",
            c_Intangible_Intellectual_Assets_Trademark: answers.c_Intangible_Intellectual_Assets_Trademark === true ? "1" : "0",
            c_Intangible_Intellectual_Assets_Patents: answers.c_Intangible_Intellectual_Assets_Patents === true ? "1" : "0",
            c_Intangible_Intellectual_Assets_Software: answers.c_Intangible_Intellectual_Assets_Software === true ? "1" : "0",
            c_Intangible_Intellectual_Assets_None: answers.c_Intangible_Intellectual_Assets_None === true ? "1" : "0",
            c_Legal_Situations_Lawsuits: answers.c_Legal_Situations_Lawsuits === true ? "1" : "0",
            c_Legal_Situations_Lien: answers.c_Legal_Situations_Lien === true ? "1" : "0",
            c_Legal_Situations_Behind_On_Rent: answers.c_Legal_Situations_Behind_On_Rent === true ? "1" : "0",
            c_Legal_Situations_Brokerage_Firm: answers.c_Legal_Situations_Brokerage_Firm === true ? "1" : "0",
            c_Legal_Situations_Debts_At_Closing: answers.c_Legal_Situations_Debts_At_Closing === true ? "1" : "0",
            c_Legal_Situations_None: answers.c_Legal_Situations_None === true ? "1" : "0",
            c_Information_Correct: answers.c_Information_Correct === true ? "1" : "0"
        }})

        if(currentPage === 6){
            
            newData = update(newData, {$merge: 
                {
                    c_Form_Submitted: true
                }
            })
        }

        const promise = await api.postQuestionnaire(newData)
        console.log("promise", promise)
        setIsLoading(false)
    }

    const completeForm = () => {
        console.log("complete form")
    }

    const advancePage = async (back) => {
        console.log("advance page", back ? currentPage + 1 : currentPage + 2)

        setCurrentPage(back ? currentPage - 1 : currentPage + 1)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        })
    }

    return {
        isLoading,
        getQuestionnaire,
        noQuestionnaire,
        currentPage,
        advancePage,
        answers,
        setAnswer,
        saveQuestionnaire
    }
}

export const QuestionnaireContainer = createContainer(useQuestionnaireContainer)
