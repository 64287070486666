/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import Page1 from "./Page1"
import Page2 from "./Page2"
import Page3 from "./Page3"
import Page4 from "./Page4"
import Page5 from "./Page5"
import Page6 from "./Page6"
import Page7 from "./Page7"
import Spinner from "./Spinner"
import { QuestionnaireContainer } from "../containers/QuestionnaireContainer"

const componentStyle = css`
    h2 {
        font-size: 22px;
    }

    label {
        font-size: 14px;
        margin-bottom: 2px;
        color: #555;
        margin-top: 12px;
    }

    .required {
        color: #ff0000;
    }

    .form-check label {
        color: #212529;
        margin: 0;
        position: relative;
        top: -2px;
    }

    .form-group {
        margin-bottom: 12px;
    }

    .text-muted {
        display: block;
        font-size: 12px;
        margin-top: -4px;
        color: #999 !important;
    }

    .error {
        color: #ff0000;
    }

    p.error {
        font-size: 14px;
        float: right;
        margin: 20px 0 0 0;
    }

    .buttons {
        clear: both;
    }

    .next {
        float: right;
        margin-top: 20px;
        margin-left: 20px;
    }

    .previous {
        float: left;
        margin-top: 20px;
    }

    .loading-background {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(355, 255, 255, 0.7);
        top: 0;
        left: 0;
        z-index: 10;
    }

    .spinner {
        margin-left: calc(50% - 12px);
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 11;
    }
`

function Questionnaire() {
    const questionnaireContainer = QuestionnaireContainer.useContainer()

    const { questionnaireId } = useParams()

    useEffect(() => {
        questionnaireContainer.getQuestionnaire(questionnaireId)
    }, [questionnaireId])

    return (
        <div css={componentStyle}>
            {questionnaireContainer.noQuestionnaire && <p>Questionnaire not found.</p>}

            {!questionnaireContainer.noQuestionnaire && (
                <>
                    {questionnaireContainer.currentPage === 1 && <Page1 />}
                    {questionnaireContainer.currentPage === 2 && <Page2 />}
                    {questionnaireContainer.currentPage === 3 && <Page3 />}
                    {questionnaireContainer.currentPage === 4 && <Page4 />}
                    {questionnaireContainer.currentPage === 5 && <Page5 />}
                    {questionnaireContainer.currentPage === 6 && <Page6 />}
                    {questionnaireContainer.currentPage === 7 && <Page7 />}
                </>
            )}

            {questionnaireContainer.isLoading && (
                <div className="loading-background">
                    <div className="spinner">
                        <Spinner color="#07481f" />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Questionnaire
