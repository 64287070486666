/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import { useEffect, useState } from "react"
import { Row, Col, Form, Button } from "react-bootstrap"
import { QuestionnaireContainer } from "../containers/QuestionnaireContainer"

const componentStyle = css`
    h5{
        margin-top: 20px;
    }
`

function Page1() {
    const questionnaireContainer = QuestionnaireContainer.useContainer()

    const [isValidating, setIsValidating] = useState(false)
    const [error, setError] = useState("")

    const next = async (advance, back) => {
        setIsValidating(true)

        if (validate(true)) {
            await questionnaireContainer.saveQuestionnaire()
            if(advance){
                questionnaireContainer.advancePage(back)
            }
            setIsValidating(false)
        }
    }

    useEffect(() => {
        validate()
    }, [questionnaireContainer.answers])

    useEffect(() => {
        if (isValidating) {
            validate(false)
        }
    }, [isValidating])

    const validate = (forceValidate) => {
        if (!isValidating && !forceValidate) {
            return false
        }

        const requiredFields = [
            "Street_Address",
            "Business_Name",
            "City",
            "State",
            "Zip_Code",
            "CurrencySymbol",
            "Telephone_Number",
            "Website_Address",
            "Business_Operation_Hours",
            "Legal_Organization_Type",
            "Years_Owned",
            "Years_In_Business",
            "Business_Relocatable",
            "Business_Franchise",
            "Special_Skills_Or_Licenses",
            "History_Of_Company"
        ]

        let error = false

        requiredFields.map((field) => {
            if (!questionnaireContainer.answers["c_" + field]) {
                error = true
            }
        })

        if (
            !questionnaireContainer.answers.c_Intangible_Intellectual_Assets_Copyright &&
            !questionnaireContainer.answers.c_Intangible_Intellectual_Assets_Trademark &&
            !questionnaireContainer.answers.c_Intangible_Intellectual_Assets_Patents &&
            !questionnaireContainer.answers.c_Intangible_Intellectual_Assets_Software &&
            !questionnaireContainer.answers.c_Intangible_Intellectual_Assets_None
        ) {
            error = true
        }

        if (error) {
            setError("Please complete required fields")
            return false
        } else {
            setError("")
            setIsValidating("")
            return true
        }
    }

    if(questionnaireContainer.answers.c_Form_Submitted){
        console.log("complete")
        return (
            <Row css={componentStyle}>
                <Col>
                    <h5>This questionnaire has already been submitted.</h5>
                </Col>
            </Row>
        )
    }else{
        console.log("answers", questionnaireContainer.answers)
    return (
        <div css={componentStyle}>
            <Row>
                <Col>
                    <p>
                        This questionnaire is meant to collect information about your business for prospective buyers.
                        The more detailed information we receive, the better equipped we will be to sell your business.
                        The questionnaire is split into two sections, the first is information to share with buyers, the
                        second is information for our files only.{" "}
                    </p>

                    <p>After you complete this form, please email the following files for your broker:</p>

                    <ol>
                        <li>Profit and Loss Statements from the last 3 years</li>
                        <li>Tax Returns from the last 3 years</li>
                        <li>Lease</li>
                        <li>Asset List</li>
                        <li>Photos of the business (optional)</li>
                    </ol>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h2>Information for Buyers</h2>
                    <p>
                        Please complete the following sections below, as detailed as possible. Your broker will edit
                        this information and send the final Pitch Deck for your approval before any information is
                        disclosed to buyers.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Business_Name ? "error" : ""}>
                        Business Name (DBA Name)<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Business_Name"
                        value={questionnaireContainer.answers.c_Business_Name}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Street_Address ? "error" : ""}>
                        Street Address<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Street_Address"
                        value={questionnaireContainer.answers.c_Street_Address}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label className={isValidating && !questionnaireContainer.answers.c_City ? "error" : ""}>
                        City<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        size="sm"
                        name="City"
                        value={questionnaireContainer.answers.c_City}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label className={isValidating && !questionnaireContainer.answers.c_State ? "error" : ""}>
                        State<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        size="sm"
                        name="State"
                        value={questionnaireContainer.answers.c_State}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label className={isValidating && !questionnaireContainer.answers.c_Zip_Code ? "error" : ""}>
                        Postal Zip Code
                    </Form.Label>
                    <span className="required">*</span>
                    <Form.Control
                        type="text"
                        size="sm"
                        name="Zip_Code"
                        value={questionnaireContainer.answers.c_Zip_Code}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_CurrencySymbol ? "error" : ""}>
                        Currency Symbol<span className="required">*</span>
                    </Form.Label>
                    <Form.Select
                        name="CurrencySymbol"
                        value={
                            questionnaireContainer.answers.c_CurrencySymbol
                                ? questionnaireContainer.answers.c_CurrencySymbol
                                : ""
                        }
                        onChange={questionnaireContainer.setAnswer}
                        size="sm"
                        required>
                        <option value=""> --- Select Currency --- </option>
                        <option value="$">$</option>
                        <option value="C$">C$</option>
                        <option value="£">£</option>
                        <option value="€">€</option>
                        <option value="R">R</option>
                        <option value="AED">AED</option>
                    </Form.Select>

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Telephone_Number ? "error" : ""}>
                        Telephone Number<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        size="sm"
                        name="Telephone_Number"
                        value={questionnaireContainer.answers.c_Telephone_Number}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Website_Address ? "error" : ""}>
                        Website address<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        size="sm"
                        name="Website_Address"
                        value={questionnaireContainer.answers.c_Website_Address}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label>State of incorporation</Form.Label>
                    <Form.Control
                        type="text"
                        size="sm"
                        name="State_Of_Incorporation"
                        value={questionnaireContainer.answers.c_State_Of_Incorporation}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Business_Operation_Hours ? "error" : ""
                        }>
                        Business Operation Hours<span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        size="sm"
                        name="Business_Operation_Hours"
                        value={questionnaireContainer.answers.c_Business_Operation_Hours}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Legal_Organization_Type ? "error" : ""
                        }>
                        Legal Organization Type<span className="required">*</span>
                    </Form.Label>
                    <Form.Select
                        name="Legal_Organization_Type"
                        value={questionnaireContainer.answers.c_Legal_Organization_Type}
                        onChange={questionnaireContainer.setAnswer}
                        size="sm"
                        required>
                        <option value="">--- Select Legal Organization Type ---</option>
                        <option value="S-Corporation">S-Corporation</option>
                        <option value="Limited Liability Corp">Limited Liability Corp</option>
                        <option value="C-Corporation">C-Corporation</option>
                        <option value="Sole Proprietorship">Sole Proprietorship</option>
                        <option value="PTY Limited">PTY Limited</option>
                        <option value="Partnership">Partnership</option>
                        <option value="Foreign">Foreign</option>
                        <option value="Corp 'Sub S">Corp 'Sub S'</option>
                        <option value="Limited Corporation">Limited Corporation</option>
                        <option value="Limited Partnership">Limited Partnership</option>
                        <option value="Corp 'C">Corp 'C'</option>
                        <option value="Foreign Corp 'Sub S">Foreign Corp 'Sub S'</option>
                        <option value="Foreign Corp 'C">Foreign Corp 'C'</option>
                        <option value="Closed Corporation">Closed Corporation</option>
                        <option value="Other">Other</option>
                    </Form.Select>

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Years_Owned ? "error" : ""}>
                        Years owned by you<span className="required">*</span>
                    </Form.Label>
                    <Form.Text muted>Round to the nearest year</Form.Text>
                    <Form.Control
                        type="number"
                        size="sm"
                        name="Years_Owned"
                        value={questionnaireContainer.answers.c_Years_Owned}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Years_In_Business ? "error" : ""}>
                        Years in business<span className="required">*</span>
                    </Form.Label>
                    <Form.Text muted>Round to the nearest year</Form.Text>
                    <Form.Control
                        type="number"
                        size="sm"
                        name="Years_In_Business"
                        value={questionnaireContainer.answers.c_Years_In_Business}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={
                            isValidating &&
                            !questionnaireContainer.answers.c_Intangible_Intellectual_Assets_Copyright &&
                            !questionnaireContainer.answers.c_Intangible_Intellectual_Assets_Trademark &&
                            !questionnaireContainer.answers.c_Intangible_Intellectual_Assets_Patents &&
                            !questionnaireContainer.answers.c_Intangible_Intellectual_Assets_Software &&
                            !questionnaireContainer.answers.c_Intangible_Intellectual_Assets_None
                                ? "error"
                                : ""
                        }>
                        Are there any significant intangible or intellectual assets included in the sale?
                        <span className="required">*</span>
                    </Form.Label>
                    <Form.Text muted>Examples include copyrights, trademarks, and patents</Form.Text>
                    <Form.Check
                        type="checkbox"
                        name="Intangible_Intellectual_Assets_Copyright"
                        checked={questionnaireContainer.answers.c_Intangible_Intellectual_Assets_Copyright}
                        label="Copyright"
                        onChange={questionnaireContainer.setAnswer}
                    />
                    <Form.Check
                        type="checkbox"
                        name="Intangible_Intellectual_Assets_Trademark"
                        checked={questionnaireContainer.answers.c_Intangible_Intellectual_Assets_Trademark}
                        label="Trademarks"
                        onChange={questionnaireContainer.setAnswer}
                    />
                    <Form.Check
                        type="checkbox"
                        name="Intangible_Intellectual_Assets_Patents"
                        checked={questionnaireContainer.answers.c_Intangible_Intellectual_Assets_Patents}
                        label="Patents"
                        onChange={(e) => {
                            questionnaireContainer.setAnswer(e)
                            validate(false)
                        }}
                    />
                    <Form.Check
                        type="checkbox"
                        name="Intangible_Intellectual_Assets_Software"
                        checked={questionnaireContainer.answers.c_Intangible_Intellectual_Assets_Software}
                        label="Proprietary Software"
                        onChange={questionnaireContainer.setAnswer}
                    />
                    <Form.Check
                        type="checkbox"
                        name="Intangible_Intellectual_Assets_None"
                        checked={questionnaireContainer.answers.c_Intangible_Intellectual_Assets_None}
                        label="None"
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Business_Relocatable ? "error" : ""
                        }>
                        Is your business relocatable?<span className="required">*</span>
                    </Form.Label>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        name="Business_Relocatable"
                        value="Yes"
                        checked={questionnaireContainer.answers.c_Business_Relocatable === "Yes"}
                        onChange={questionnaireContainer.setAnswer}
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        name="Business_Relocatable"
                        value="No"
                        checked={questionnaireContainer.answers.c_Business_Relocatable === "No"}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_Business_Franchise ? "error" : ""}>
                        Is your business a franchise?<span className="required">*</span>
                    </Form.Label>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        value="Yes"
                        name="Business_Franchise"
                        checked={questionnaireContainer.answers.c_Business_Franchise === "Yes"}
                        onChange={questionnaireContainer.setAnswer}
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        value="No"
                        name="Business_Franchise"
                        checked={questionnaireContainer.answers.c_Business_Franchise === "No"}
                        onChange={questionnaireContainer.setAnswer}
                    />

                    <Form.Label
                        className={
                            isValidating && !questionnaireContainer.answers.c_Special_Skills_Or_Licenses ? "error" : ""
                        }>
                        Are there special skills or licenses required to operate the business?
                        <span className="required">*</span>
                    </Form.Label>
                    <Form.Text muted>
                        Examples include liquor permits, professional licenses. Please define their type and expiration.
                    </Form.Text>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="Special_Skills_Or_Licenses"
                        value={questionnaireContainer.answers.c_Special_Skills_Or_Licenses}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />

                    <Form.Label
                        className={isValidating && !questionnaireContainer.answers.c_History_Of_Company ? "error" : ""}>
                        Please give us a brief description and history of your company. Include how the company was
                        started and how it has grown.<span className="required">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        name="History_Of_Company"
                        value={questionnaireContainer.answers.c_History_Of_Company}
                        onChange={questionnaireContainer.setAnswer}
                        required
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    {error && <p className="error">{error}</p>}
                    <div className="buttons">
                        <Button
                            className="next"
                            size="sm"
                            variant="success"
                            onClick={()=>next(true)}>
                            Save &amp; Continue
                        </Button>
                        <Button
                            className="next"
                            size="sm"
                            variant="success"
                            onClick={()=>next(false)}>
                            Save for Later
                        </Button>                     
                    </div>
                </Col>
            </Row>
        </div>
    )
    }
}

export default Page1
